<template>
    <div>
      <order-table :item="item" :isAdmin="true" />
      <vs-card>
        <div class="hidden lg:block w-1/2">
            <p><strong>Email Activity</strong></p>
            <div class="mt-2 bordered" v-for="email in emails" :key="email._id">
                <p v-if="email.emailSuccess || email.emailSuccess === null" class="color">
                    <span class="success"> OK </span> {{ email.name }} - {{ moment(email.sendDate).format("LLL") }}
                </p>
                <p v-else :style="{ color: 'indianred' }">
                    <span class="fail"> FAIL </span> {{ email.name }}
                    <vs-button size="small" @click="sendEmail(email._id)">RETRY</vs-button>
                </p>
            </div>
            <div class="mt-2 bordered" >
              <p v-if="customerEmail" class="color">
                    <span class="success"> OK </span> Customer PO Email
                    <vs-button size="small" @click="sendEmail(id, true)">RETRY</vs-button>
                </p>
                <p v-else :style="{ color: 'indianred' }">
                    <span class="fail"> FAIL </span> Customer PO Email
                    <vs-button size="small" @click="sendEmail(id, true)">RETRY</vs-button>
                </p>
            </div>
        </div>
      </vs-card>
      <vs-prompt
          title="PO Email"
          :active.sync="showModal"
          @accept="resubmitToXero()"
          accept-text="Send">
          <div>
            Are you sure you want to send this PO email?
          </div>
        </vs-prompt>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import { mapActions } from "vuex";
  
  export default {
    name: "EmailDetails",
    props: {
      orderId: {
        type: String,
      },
    },
    data() {
      return {
        id: this.orderId,
        emails: [],
        showModal: false,
        supplierId: null,
        customerEmail: false,
        customerEmailSent: null,
        isCustomer: false
      };
    },
    computed: {
      validateForm() {
        return !this.errors.any();
      },
    },
    mounted() { },
    methods: {
      ...mapActions("storeOrder", [
        "getOrderDetails",
        "resendEmail",
        "resendCustomerEmail"
      ]),
      moment(date) {
        return moment(date);
      },
      sendEmail(supplierId, isCustomer = false) {
        this.showModal = true;
        this.isCustomer = isCustomer;
        if(!isCustomer)  {
          this.supplierId = supplierId
        }
      },
      async resubmitToXero() {
        this.$vs.loading();
        let self = this;
        if(this.isCustomer) {
          await this.resendCustomerEmail(this.id)
          .then((res) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: res.data.title,
              text: res.data.message,
              color: "success",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
            setTimeout(()=>{
              window.location.reload();
            },1000);
          })
          .catch((error) => {
            console.log(error,'error here')
            this.$vs.loading.close();
            self.$vs.notify({
              title: error.data.title,
              text: error.data.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
            self.$vs.loading.close();
          });
        } else {
          await this.resendEmail({orderId: this.id, supplierId: this.supplierId})
          .then((res) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: res.data.title,
              text: res.data.message,
              color: "success",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
            setTimeout(()=>{
              window.location.reload();
            },1000);
          })
          .catch((error) => {
            console.log(error,'error here')
            this.$vs.loading.close();
            self.$vs.notify({
              title: error.data.title,
              text: error.data.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
            self.$vs.loading.close();
          });
        }
      },
      async fetchOrderDetails(id) {
        this.$vs.loading();
        await this.getOrderDetails(id).then((res) => {
          const result = res.data.data.result[0];
          this.customerEmail = result.hasOwnProperty("customerEmail") ? result.customerEmail : true;
          this.customerEmailSent = result.hasOwnProperty("customerEmailSent") ? moment(result.customerEmailSent).format("LLL") : moment(result.createdAt).format("LLL");
          if(result.newsuppliers) {
            result.newsuppliers.map((email)=>{
              this.emails.push({
                ...email,
                emailSuccess: email.hasOwnProperty("emailSuccess") ? email.emailSuccess : true,
                sendDate: email.hasOwnProperty("sendDate") ? email.sendDate : result.createdAt,
              })
            });
          }
          this.$vs.loading.close();
        }).catch(err=>{
          console.log(err,'error here')
        });
      },
    },
    async created() {
      this.id = this.orderId;
      await this.fetchOrderDetails(this.id);
    },
  };
  </script>
  
  <style scoped></style>
  <style>
  .color {
    color: #3dc9b3;
  }
  
  .bordered {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
  }
  
  .success {
    color: white;
    background-color: lightgreen;
    padding-left: 3px;
    padding-right: 3px;
    margin-right: 5px;
    border-radius: 5px;
  }
  
  .fail {
    color: white;
    background-color: indianred;
    padding-left: 3px;
    padding-right: 3px;
    margin-right: 5px;
    border-radius: 5px;
  }
  </style>
  